// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Typography, Container, Button } from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import { fetchMerchantPDF } from '../../../redux/merchant-pdf';
import { salesFormSubmittedStyle } from '../ContactForm/styles';

const FormSubmitted = ({
  _fetchMerchantPDF,
  merchantPdf,
}: {
  _fetchMerchantPDF: Function,
  merchantPdf: Object,
}) => {
  const classes = salesFormSubmittedStyle({});

  useEffect(() => {
    _fetchMerchantPDF();
  }, []);

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    setTimeout(() => {
      if (merchantPdf && merchantPdf.materialsPdf) {
        // We want to get the last part as the file name
        const pathArr = merchantPdf.materialsPdf.file.url.split('/');

        fetch(merchantPdf.materialsPdf.file.url, {
          method: 'get',
          headers: {
            Accept: 'application/octet-stream',
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');

            link.href = url;
            link.download = pathArr[pathArr.length - 1];
            // Simulate a click to initiate download
            link.click();
          });
      }
    }, 1000);
  }

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        py="100px"
        alignItems="center"
        maxWidth="780px"
        m="0 auto"
      >
        <Box textAlign="center" mb="70px">
          <Typography variant="h2" component="h4">
            お申込みありがとうございました。
          </Typography>
        </Box>
        <Typography variant="body1" component="p">
          この度は、資料のご請求誠にありがとうございます。
          <br className={classes.sp} />
          追って担当者より、3営業日以内に、ご連絡させていただきます。それまでの期間、ぜひ資料をご確認の上、お待ちください。
        </Typography>
        <Box mt="70px">
          <Button
            variant="contained"
            color="primary"
            to="/merchant/"
            component={GatsbyLink}
          >
            トップへ戻る
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  merchantPdf: state.merchantPdf.data,
});

const mapDispatchToProps = (dispatch: Function) => ({
  _fetchMerchantPDF: () => dispatch(fetchMerchantPDF()),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmitted);
