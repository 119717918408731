// @flow
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../../../components/Layout/Layout';
import ApplySection from '../../../../components/Merchant/ApplySection';
import Hero from '../../../../components/Merchant/shared/Hero';
import bg from '../../../../images/materials.svg';
import SalesFormSubmitted from '../../../../components/Merchant/SalesContactForm/SalesFormSubmitted';
import { gtag } from '../../../../utils';

const SalesPageCompleted = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  useEffect(() => {
    gtag({
      event: 'conversion',
    });
  }, []);

  return (
    <>
      <Layout isMerchant>
        <Hero title="資料請求" bg={bg} />
        <SalesFormSubmitted />
        <ApplySection />
      </Layout>
      {/* a8_material_download trigger as set on Google Tag Manager */}
      <span id="a8sales"></span>
      <Helmet
        script={[
          // Google Tag Manager script AW-536590176 is only run on this pages
          {
            src: 'https://www.googletagmanager.com/gtag/js?id=AW-536590176',
          },
          {
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'AW-536590176');
            `,
          },
          {
            innerHTML: `
            gtag('event', 'conversion', {'send_to': 'AW-536590176/eIFwCO-A6eIBEODu7v8B'});
            `,
          },
          // Google script for triggering a8_material_download, only run on this page
          {
            innerHTML: `
            a8sales && a8sales({
              "pid": "s00000021514001",
              "order_number": "",
              "currency": "JPY",
              "items": [
                {
                  "code": "a8",
                  "price": 5000,
                  "quantity": 1
                },
              ],
              "total_price": 5000,
            });
            `,
          },
          // Google script for triggering ad_ebis_material_download, only run on this page
          {
            innerHTML: `
            ebis && ebis({
              argument: '5q6EwpJm',
              page_id: 'request',
              member_name: '',
              amount: '',
              other1: '',
              other2: '',
              other3: '',
              other4: '',
              other5: '',
            });
            `,
          },
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            var __atw = __atw || [];
         
            __atw.push({
            "merchant" : "paidy", "param" : {
                "result_id" : "100",
                "verify" : "${id}",
            }});
            (function(a){var b=a.createElement("script");b.src="https://h.accesstrade.net/js/nct/cv.min.js";b.async=!0;
            a=a.getElementsByTagName("script")[0];a.parentNode.insertBefore(b,a)})(document);`,
          },
        ]}
      />
    </>
  );
};

// $FlowFixMe
export default SalesPageCompleted;
